<template>
	<div id="aOperat_record" class="el-content">
		<span style="color: #868686;font-size: 14px;margin-left: 20px;" v-if="$store.state.userInfo && $store.state.userInfo.rank != 1 && canUse">
			<span>当前剩余授权</span>
			<span v-for="(item, index) in canUse" :key="index">{{ item.server_name }} : {{ item.limit }} 个 &nbsp;&nbsp;</span>
		</span>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'应用',dataIndex:'app.program_name'},
			{title:'版本',dataIndex:'server.server_name'},
			{title:'描述',dataIndex:'description'},
			{title:'操作数量',dataIndex:'count'},
			{title:'应用版本',dataIndex:'apply_version'},
			{title:'时间',dataIndex:'to_server.server_name'},
		]">
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange=" (page, e) => { getRecordList(info.page, e); } "
				@change=" e => { getRecordList(e, info.limit); } "
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			canUse: []
		})
		getRecordList(1,state.info.limit)

		function getRecordList(page,limit){
			programModel.getProgramOperatRecord(page,limit,{type:2},res=>state.info = {limit,...res})
		}

		function getTotal() {
			programModel.getProgramAuthCount(res=>state.canUse = res)
		}

		return{
			...toRefs(state),
			getRecordList,
			getTotal
		}
	}
}
</script>

<style></style>
